import request from '@/utils/request'
import axios from "axios";
import {local, LOCAL_STORAGE} from "@/utils/storage";

export const getCommentList = (params?: any): Promise<any> => {
    return request.get('/web/comments', {params})
}

export const getComment = (id: number): Promise<any> => {
    return request.get(`/web/comments/${id}`)
}

export const addComment = (params: any): Promise<any> => {
    return request.post('/web/comments', params)
}

export const editComment = (id: number, params?: any): Promise<any> => {
    return request.put(`/web/comments/${id}`, params)
}

export const deleteComment = (id: number): Promise<any> => {
    return request.delete(`/web/comments/${id}`)
}

export const addFavorite = (params: any): Promise<any> => {
    return request.post('/web/favorites', params)
}

export const deleteFavorite = (id: number): Promise<any> => {
    return request.delete(`/web/favorites/${id}`)
}

/**
 * 封装 SSE 请求，支持流式数据处理
 * @param params 请求的 POST 参数
 * @param onMessage 消息处理回调函数
 * @param onError 错误处理回调函数
 * @returns Promise<void>
 */
export const fetchStreamResponse = async (
    params: Record<string, any>,
    onMessage: (message: string) => void,
    onError?: (error: Error) => void
): Promise<void> => {
    const token = local.get(LOCAL_STORAGE.token)
    const companyId = (local.get(LOCAL_STORAGE.company))?.id
    const response = await fetch(process.env.VUE_APP_API_BASE_URL + "/web/chat/with-ai", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Token": token,
            "Company": companyId
        },
        body: JSON.stringify(params),
    });

    if (!response.body) {
        throw new Error("Response body is null or undefined");
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");

    while (true) {
        const { done, value } = await reader.read();
        if (done) {
            break;
        }

        const chunk = decoder.decode(value, { stream: true });
        chunk.split("\n").forEach((line) => {
            line = line.trim()
            if (!line) {
                return
            }
                //console.log("line: " + line)
            //onMessage(line + "\n");
            try {
                const data = JSON.parse(line);
                if (data.content) {
                    onMessage(data.content);
                }
            } catch (error) {
                console.error("Error parsing stream data:", error);
                console.info("line: " + line)
            }
        });
    }
    // try {
    //     console.log("准备请求")
    //     const response = await request({
    //         url: "/web/chat/with-ai", // 请求的 API 路径
    //         method: "POST",
    //         data: params, // POST 请求的参数
    //         responseType: "stream", // 处理流式数据
    //     });
    //     console.log("请求结束")
    //     console.log("resp: " + response)
    //
    //     const reader = response.data.getReader();
    //     const decoder = new TextDecoder("utf-8");
    //
    //     while (true) {
    //         const { done, value } = await reader.read();
    //         if (done) {
    //             break;
    //         }
    //
    //         const chunk = decoder.decode(value, { stream: true });
    //         chunk.split("\n").forEach((line) => {
    //             if (line.startsWith("data:")) {
    //                 try {
    //                     const data = JSON.parse(line.slice(5)); // 去掉 "data: " 前缀
    //                     if (data.content) {
    //                         onMessage(data.content);
    //                     }
    //                 } catch (error) {
    //                     console.error("Error parsing stream data:", error);
    //                 }
    //             }
    //         });
    //     }
    // } catch (error) {
    //     // 类型保护，确保 error 是 Error 类型
    //     if (error instanceof Error) {
    //         console.error("Error in fetchStreamResponse:", error);
    //         onError?.(error);
    //     } else {
    //         console.error("Unknown error:", error);
    //         onError?.(new Error(String(error))); // 将其他类型的错误转换为 Error
    //     }
    // }
};

// export const fetchStreamResponse = async (
//     params: Record<string, any>,
//     onMessage: (message: string) => void,
//     onError?: (error: Error) => void
// ): Promise<void> => {
//     try {
//         const { data: stream } = await axios.post("/web/chat/with-ai", params, {
//             responseType: "text", // 接收文本数据并手动解析
//         });
//
//         const lines = stream.split("\n");
//         for (const line of lines) {
//             if (line.startsWith("data:")) {
//                 try {
//                     const data = JSON.parse(line.slice(5));
//                     if (data.content) {
//                         onMessage(data.content);
//                     }
//                 } catch (error) {
//                     console.error("Error parsing stream data:", error);
//                 }
//             }
//         }
//     } catch (error) {
//         if (error instanceof Error) {
//             console.error("Error in fetchStreamResponse:", error);
//             onError?.(error);
//         } else {
//             console.error("Unknown error:", error);
//             onError?.(new Error(String(error)));
//         }
//     }
// };