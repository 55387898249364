import request from '@/utils/request'

export const list = (params: any): Promise<any> => {
    return request.get('/web/bpm-flow-paths', {params})
}

export const trash = (id: number): Promise<any> => {
    return request.delete(`/web/bpm-flow-paths/${id}`)
}

export const changeStatus = (id: number, status: string): Promise<any> => {
    return request.put(`/web/bpm-flow-paths/${id}`, {status})
}

export const exportApply = async (): Promise<any> => {
    return request.get('/web/form/apply_export', {
        responseType: 'blob',
        // headers:{},
        // withCredentials: true
    });
}